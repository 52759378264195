/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

@font-face {
  font-family: 'Conv_DINPro';
  src: url('./ui/fonts/DINPRO-REGULAR.eot');
  src: local('☺'), url('./ui/fonts/DINPRO-REGULAR.woff') format('woff'), url('./ui/fonts/DINPRO-REGULAR.ttf') format('truetype'), url('./ui/fonts/DINPRO-REGULAR.svg') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}


html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  font-display: swap;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before,
blockquote:after {
  content: '';
  content: none;
}

q:before, q:after {
  content: '';
  content: none;
}

html,body {
  font-family: 'Conv_DINPro', 'Helvetica', sans-serif;
  font-size: 10px;
  font-weight: 400;
  font-display: swap;
}

h1,h2,h3,h4,h5,h6 {
  font-weight: bold;
  padding: 0 0 1.5rem;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 1rem;
}

table th, table td {
  border: 1px solid #ddd;
  padding: 10px;
}

strong {
  font-weight: bold;
}

h3 {
  font-size: 1.6rem
}

p {
  font-size: 1.4rem;
  padding: 0 0 1.5rem;
  line-height: 1.8rem;
}

*:focus {
  outline: none;
}

* {
  box-sizing: border-box;
}

img {
  max-width: 100%;
  height: auto;
}

li {
  list-style-type: none;
}

a {
  transition: all .5s;
}

em {
  font-style: italic;
}

.icon-close-x {
  cursor: pointer;
}

.slick-prev {
  left: -50px
}

.slick-next {
  right: -50px
}

.slick-prev, .slick-next {
  width: 40px;
  height: 40px;
}

.slick-vertical .slick-slide {
  text-align: center;
}
.slick-vertical .slick-slide img {
  height: 8rem;
  width: auto;
  padding: 1rem;
}
.slick-vertical .slick-current img {
  border: 1px solid #ccc
}

.slick-next:before, .slick-prev:before{
	font-family: 'Conv_DINPro', 'Helvetica', sans-serif !important;
	font-display: swap;
}

.main_container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 163rem;
  margin: 0 auto;
}

main {
  padding: 12rem 0 0;
  transition: all 0.5s;
}

main.main_mobile {
  padding: 0;
}

.content_btn {
  background: #ae946d !important;
  color: #fff !important;
  line-height: 4rem !important;
  border: solid 1px #ccc;
  display: inline-block;
  text-decoration: none;
  padding: 0 2rem;
  text-transform: uppercase;
  font-size: 1.4rem !important;
  transition: all 0.5s;
}

.content_btn:hover {
  background: #000;
}

@media (max-width: 1400px) {
  main {
    padding: 18.8rem 0 0;
  }
}